<template>
  <div>
    <div class="modal-pagetitle text-center text-white text-uppercase">
      Newsletter
    </div>
    <div class="modal-prompt">
      <!-- <p>
        Dein Account ist jetzt ein Meine Krone Account. Für dich ändert sich
        nichts. Du kannst dich wie bisher anmelden.
      </p> -->

      <p>
        Wir schicken allen Spielern täglich Geschenke per E-Mail, damit sie gut
        ausgestattet in Turniere und andere Events starten können.
      </p>
      <p>Möchtest du diese Geschenke auch erhalten?</p>

      <div class="tos-package">
        <div
          v-for="(reward, index) in newsletterRewards"
          :key="reward.type + index"
          class="package-item"
        >
          <img draggable="false" :src="getRewardIcon(reward)" class="icon" />
          <div>{{ formatNumber(reward.amount) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ModalToSPageOptIn1",
  props: {
    newsletterRewards: {
      type: Array,
      required: true,
    },
  },
  methods: {
    getRewardIcon(reward) {
      if (reward.type && reward.type === "gold") {
        return require("@/assets/img/common/coin.png");
      } else if (reward.type && reward.type === "crown") {
        return require("@/assets/img/common/crown.png");
      } else if (!reward.type && reward.asset.endsWith("Card")) {
        return require("@/assets/img/common/cards.png");
      } else if (!reward.type && reward.asset.endsWith("Elixir")) {
        return require("@/assets/img/common/elixir.png");
      } else {
        return require("@/assets/img/common/coin.png");
      }
    },
  },
};
</script>
