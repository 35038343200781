<template>
  <div>
    <modal-winter-real-rewards
      v-if="showRealRewardsPopup"
    ></modal-winter-real-rewards>
  </div>
</template>
<script>
import ModalWinterRealRewards from "@/components/events/winter-bonus-jagd/modal-winter-real-rewards";
export default {
  name: "PipePass",
  components: { ModalWinterRealRewards },
  data() {
    return {};
  },

  computed: {
    showRealRewardsPopup() {
      return this.$store.state.popups.showKingspassRealRewardsPopup;
    },
    showWinnersPopup() {
      return this.$store.state.popups.showKingspassRealWinnersPopup;
    },
    routeName() {
      return this.$route.name;
    },
  },

  mounted() {
    console.log("--------- Pipeline Step Pass ----------");

    this.$store.dispatch("events/checkForPasses").then(() => {
      //real rewards ad popup
      if (
        this.showRealRewardsPopup &&
        this.popupsUnlocked &&
        this.eventsUnlocked
      ) {
        this.$store.commit("popups/disableKingspassRealRewardsPopup");
        this.$bvModal.show("modal-winter-real-rewards");
        return;
      }

      //show winners of pass event
      if (this.showWinnersPopup && this.popupsUnlocked) {
        //not during other routes than the lobby
        if (this.routeName !== "home" && this.routeName !== "GameGrid") {
          return false;
        }

        this.$store.commit("popups/disableKingspassRealRewardsWinnerPopup");
        this.$bvModal.show("modal-winter-bonus-jagd-winner");
        return;
      }

      this.$store.commit("popups/setNextPipelineQueueStep");
    });
  },
  methods: {},
};
</script>
